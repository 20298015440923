*{
   margin: 0;
   padding: 0;
   box-sizing: border-box;
}

.newuser-page{
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f7faf2;
}

.desktop-newuser{
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  margin-left: 20px;
  margin-top: 10px;
}



.mainbox-new-user {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100% !important;
 }

 .main-container-user {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
 }





.ownerDetails p:nth-child(1){
  color: #061c72;
  font-size: 18px;
  font-weight: bolder;
  margin-bottom: 0;
}
.ownerDetails p:nth-child(2){
margin-bottom: 0 ;
font-size: 14px; 
font-weight: 600;
color: rgb(120, 113, 113);
}
.ownerDetails p:nth-child(3){
margin-bottom: 0;
font-size: 14px; 
font-weight: 600;
color: rgb(120, 113, 113);
}

 .cover-image-one {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 500px;
  height: 500px;
 }
 


 .new-user-card{
  display: flex;
  flex-direction: row;
  /* border-radius: 8px; */
  width: 40%;
 }



 .new-user-card-one{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  width: 70%;
 }

 .signUp {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 6px;
 }
 
 .signUp p {
  color: #1e3cb5;
  font-size: 26px; 
  font-weight: bolder;
 }

 .input-container-one,.input-container-two,.input-container-three{
  width: 100%;
  display: flex;
  flex-direction: column;
 }

 .input-Box {
   display: flex;
   flex-direction: column;
   justify-content: space-around;
   align-items: flex-start;
   position: relative;
   width: 100%;
   gap: 20px;
 }

 .input-Box input {
   width: 100%;
   padding: 14px;
   border-radius: 6px;
   background: white;
   color: #271313;
   border: 0px solid;
   outline: none;
   box-shadow: none;
   font-weight: 400;
   font-size: 14px;
   /* letter-spacing: 0.1em; */
 }

 .quickLinks-q-new{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  padding: 30px 10px;
  background: linear-gradient(#f7faf2, #f7faf2, #f7faf2) !important;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
 }
 
 
.Button-us{
  width: 100%;
}
 
 .error-message1{
  font-size: 12px;
  color: red;
  margin-top: -1.2rem;
  margin-bottom: 1rem;
 }
 
 .pincode-spinner {
   
   position: absolute;
   top: 0;
   right: 0;
   bottom: 6px;
   display: flex;
   align-items: center;
   background-color: #fff; 
   background-color: rgba(255, 255, 255, 0.1);
   padding-bottom: 12px;padding-right: 16px;
 }

 .mobile-newuser{
  display: none;
  visibility: hidden;
}

#submitnewuser {
  width: 100%;
  padding: 15px;
  border-radius: 6px;
  font-weight: 500;
  font-size: 15px;
  border: 0px solid;
  color: white;
  background-color: #607df1;
}



 


 @media only screen and (max-width:767px) {
  .main-container-user {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .newuser-page{
   height: 100vh;
  }

.quickLinks-q-new{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  padding: 50px 10px 0 10px;
  background: linear-gradient(#f7faf2, #f7faf2, #f7faf2) !important;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}


  .desktop-newuser{
    display: none;
    visibility: hidden;
  }

  .mobile-newuser{
    visibility: visible;
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    position: relative;
    top: 30px;
    left: 20px;
  }

 
  .cover-image-one{
    visibility: hidden !important;
    display: none !important;
  }

  .new-user-card {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 40px;
    margin-bottom: 20px;

  }

  .new-user-card-update {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-bottom: 120px;
  }

  
  .new-user-card-one {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    position: relative;
    top: 30px;
  }

  .input-container-one,.input-container-two,.input-container-three{
    width: 100%;
  }

  #fullname,#email,#pincode {
    width: 100%;
  padding: 14px;
  border-radius: 6px;
  background: white;
  color: rgb(120, 113, 113);
  border-style: none;
  font-weight: 400;
  font-size: 16px;
  }

}

.input-container-one-user,.input-container-two-user,.input-container-three-user{
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
 }

.input-Box input:disabled {
 background-color: rgb(217, 217, 217) !important;
}



