* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  
}

.snapshots-container {
  width: 100%;
  box-sizing: border-box;
  margin: 12px 0px 0px 0px;
  padding:10px 4px 20px 4px ;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  
}



.snapshot-title{
   display:block;
   position: relative;
   font-size: 15px;
   left: 5px;
}



.container-one {
  padding: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}


.container-two{
  margin-top: 0px;
  padding: 0px 4px;
 display: flex;
 flex-direction: row;
 justify-content: space-around;
}

.approved {
  width: 49%;
  box-sizing: border-box;
  height: 64px;
  background-color: #d5d6ff;
  border-radius: 8px;
}

.inprocess {
  width: 49%;
  box-sizing: border-box;
  height: 64px;
  border-radius: 8px;
  background-color: #fff0a9;
}

.rejected {
  width: 49%;
  box-sizing: border-box;
  height: 64px;
  border-radius: 8px;
  background-color: #ffb0a6;
}

.viewmore{
  width: 49%;
  box-sizing: border-box;
  height: 64px;
  border-radius: 8px;
  background-color: rgb(153, 199, 245);
}


.image {
  position: relative;
  float: right;
  height: 30px;
  top: 15px;
  right: 10px;
}

.leadsCount{
   display: block;
   position: relative;
   top: 12px;
   left: 10px;
   font-size: 16px;
   font-weight: 500;
}

.leadsName{
   display: block;
   position: relative;
   bottom: 8px;
   left: 11px;
   font-size:12px ;
   color: black;
}

 .linkLeads{
   text-decoration: none;
   color: black;
 }

 @media only screen and (min-width:468px){
   .snapshots-container{
      width: 50%;
      margin: auto;
   }
 }

 /* SnapshotsShimmer CSS */
 .title-shimmer{
  margin-bottom: -1px;
  display: inline-block;
}

.leads-count-shimmer {
  width: 49%;
  box-sizing: border-box;
  border-radius: 8px;
}
