.skeleton-slider {
   height: 190px;
   width: 95%;
   border-radius: 28px;
   margin: 0 auto;
   animation: skeleton-slider-loading 1s linear infinite alternate;
 }
 
 @keyframes skeleton-slider-loading {
   0% {
     background-color: hsl(200 , 20% , 70%)
   }
   25% {
     background-color: hsl(120, 20%, 95%)
   }
   50% {
      background-color: hsl(200, 100%, 97%)
    }
    75% {
      background-color: hsl(180, 1%, 87%)
    }
    100% {
      background-color: hsl(0, 5%, 92%)
    }
 }

 @media only screen and (min-width: 468px) {
   .skeleton-slider {
     margin: 0 auto;
     width: 50%;
     height: 190px;
     z-index: 1;
   }
 }
 