.modal-wrapper{
   position: fixed;
   left: 0;
   right: 0;
   bottom: 0;
   top: 0;
   z-index: 4;
   background-color: rgba(169, 169, 169, 0.5);
}

.modal-container{
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   margin: auto;
   max-width: 15rem;
   background-color: #fff;
   position: fixed;
   max-width: 30rem;
  padding: 16px;
  border-radius: 6px;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
}


.ok{
   width: 30px;
   height: 30px;
   align-self: center;
}

.modal-container p{
   font-size: 12px;
   position: relative;
   top: 3px;
}

.okButton{
   border-radius: 6px;
   background-color: #339af0;
   color: #ffffff !important;
   padding: 0 6px;
   border: 0;
}