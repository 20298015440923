* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.otp-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f7faf2;
}

.mobile-otp {
  display: none;
  visibility: hidden;
}

.desktop-otp {
  margin-top: 10px;
}

.main-container-otp {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin-top: 50px;
  margin-bottom: 30px;
}

.cover-image-otp {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 300px;
  height: 300px;
}

.cover-image-otp img {
  width: 400px;
  height: 400px;
}

.otp-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  width: 40%;
}

.otp-card-one {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  width: 70%;
}

.welcome-message {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 20px;
  position: relative;
  top: 15px;
}

.welcome-message p {
  color: #1e3cb5;
  font-size: 22px;
  font-weight: bolder;
}

.welcome-message p span {
  color: #1e3cb5;
}

.welcome-message p:nth-child(2) {
  color: rgb(120, 113, 113) !important;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 0;
  margin-top: -1.2rem;
  align-self: center;
}

.otpHeader p {
  position: relative;
  color: #1e3cb5;
  font-size: 20px;
  font-weight: 500;
  position: relative;
  top: 15px;
}

.otpAuth {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgb(120, 113, 113) !important;
  font-weight: 400;
  font-size: 16px;
}

.inputs {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 80%;
  outline-style: none;
}

.inputs input {
  width: 100%;
  border-radius: 5px;
  padding: 12px;
  border: 0px;
  color: rgb(120, 113, 113);
  outline-style: none;
}

.otp-input {
  padding: 0 10px;
  outline-style: none;
}
.inputs input:focus {
  color: gray;
  border: 0px solid;
  outline-style: none;
}

.Button1 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  margin-top: 10px;
}

#verifyEnteredOtp:disabled {
  background-color: #c8c8c8;
}



#verifyEnteredOtp {
  width: 100%;
  padding: 14px;
  border-radius: 6px;
  font-weight: 500;
  font-size: 15px;
  border: 0px solid;
  color: white;
  background-color: #607df1;
}

.resend-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 14px;
}

.content .resend span {
  color: rgb(81, 87, 87);
}

.resend p span {
  color: rgba(120, 93, 93, 0.9);
}

.error-message2 {
  font-size: 12px;
  color: red;
  /* align-self: flex-start; */
  /* padding-left: 18px; */
  margin-bottom: 0;
}

@media only screen and (max-width: 767px) {
  .cover-image-otp {
    visibility: hidden !important;
    display: none !important;
  }

  .otp-page {
    height: 100vh;
  }

  .mobile-otp {
    display: flex;
    flex-direction: column;
    justify-content: center;
    visibility: visible;
    width: 95%;
    margin-left: 20px;
    position: relative;
    top: 10px;
    text-wrap: wrap;
  }

  .main-container-otp {
    margin-bottom: 40px;
  }

  .otp-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center !important;
    border-radius: 8px;
    width: 100%;
  }

  .desktop-otp {
    visibility: hidden;
    display: none;
  }

  .otp-card-one {
    display: flex;
    width: 95% !important;
  }

  .otpHeader {
    margin-bottom: 8px !important;
  }
  .otp-card {
    display: flex;
    flex-direction: column;
    justify-content: baseline;
    align-items: baseline;
    border-radius: 8px;
    width: 100%;
  }

  .otp-card-one {
    display: flex;
    justify-content: center !important;
    width: 100%;
  }

  .inputs {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .inputs input {
    width: 92%;
    padding: 14px;
    border-radius: 6px;
    background-color: #fff;
    color: rgb(120, 113, 113);
    border-style: none;
    font-weight: 400;
    font-size: 16px;
    outline-style: none;
  }

  .Button1 {
    display: flex;
    align-items: center;
    width: 100%;
  }

  #verifyEnteredOtp {
    width: 93%;
    margin-top: 10px;
  }

  .otpHeader {
    display: flex;
    justify-content: center !important;
    align-items: center;
  }
  .otpAuth {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
}
