* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}




.home-page{
margin-top: 60px;
margin-bottom: 60px;
overflow: scroll;
background-color: white;
}

.home-page::-webkit-scrollbar{
  display: none; 
}

.owner-container {
  display: flex;
  flex-direction: row;
  
}

.navbar-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  position: fixed;
  top: 0;
  z-index: 11;
  padding: 8px 8px;
  width: 100%;
}

.ownericon{
  margin: auto;
  width: 25px;
  height: 25px;
}




.agent{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 8px;
}

.ownername {
  margin-bottom: 0;
  font-size: 13px;
  font-weight: bolder;
  text-transform: capitalize;
  color: rgb(5, 5, 108);
  align-self: flex-start;
}

.agentname{
  margin-bottom: 0;
  font-size: 13px;
  font-weight: bolder;
  text-transform: capitalize;
  color: rgb(5, 5, 108);
  align-self: flex-start;
}

.ownermobile {
  margin-bottom: 0;
  font-size: 11px;
  color: gray;
  align-self: flex-start;
}

.agentmobile{
  margin-bottom: 0;
  font-size: 11px;
  color: gray;
  align-self: flex-start;
}

@media only screen and (min-width:768px){
  .navbar-container{
    width: 100%;
  }

  .ownericon{
    margin: auto;
    width: 40px;
    height: 40px;
  }
}