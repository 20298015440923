.filter-wrapper {
  margin-top: 52px;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-top: 1px solid rgb(150, 149, 149);
}

.filter-header {
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding: 10px 15px;
  border-bottom: 0.5px solid rgb(222, 222, 222);
  background-color: rgb(255, 255, 255);
}

.filter-header p:nth-child(1) {
  margin: 0;
  color: black;
  font-weight: 600;
  font-size: 15px;
}

.filter-header p:nth-child(2) {
  margin: 0;
  color: #4285f4;
  font-weight: 500;
  cursor: pointer;
  font-size: 15px;
}

.filter-container {
  display: flex;
  height: calc(100dvh - 214px);
}

.filter-list {
  background-color: aliceblue;
  width: 35%;
  box-shadow: 0 5px 10px #505c620f inset, 0 3px 6px #505c621c;
}

.filter-list ul {
  display: flex;
  flex-direction: column;
  list-style: none;
  gap: 10px;
  padding: 0 !important;
}

.filter-item {
  width: 65%;
  overflow-y: auto;
  background-color: #fff;
}

/* Filter Item CSS */
.f-wrapper {
  padding: 10px 10px;
  color: black;
  font-size: 15px;
  font-weight: 600;
}

.filter-name {
  padding: 10px 15px;
  font-size: 13px;
  cursor: pointer;
}

.filter-name.active {
  background-color: #ffffff;
  color: #4285f4;
  font-weight: 600;
}

.f-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}

.f-data input {
  margin-right: 5px;
  outline-style: none;
}

.f-data label {
  color: black;
  font-weight: 100;
  font-size: 14px;
  position: relative;
  bottom: 2.5px;
}

.filter-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-top: 0.5px solid rgb(222, 222, 222);
  border-bottom: 1px solid rgb(150, 149, 149);
  /* background-color: aliceblue; */
  /* box-shadow: 0 5px 10px #505c620f inset, 0 3px 6px #505c621c; */
}

.clearFilter {
  width: 50%;
  padding: 10px;
}

.showFilter {
  width: 50%;
  padding: 10px;
}

.filter-button {
  display: block;
  margin: auto;
  padding: 7px 12px;
  border-style: none;
  border-radius: 8px;
  background-color: #4285f4;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
}

.clear {
  background-color: #ffffff;
  color: #4285f4;
  border: 0.5px solid rgb(238, 238, 238);
}

.apply-button {
  border-radius: 8px;
  width: 100px;
  border-style: none;
  padding: 7px 10px;
  color: #fff;
  background-color: #4285f4;
}

/* DateFilter CSS */
.date_format {
  margin-bottom: 0;
  font-weight: 500;
  position: relative;
  top: 8px;
  font-size: 12px;
  color: gray;
}

.category-name {
  display: inline;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0.1rem;
  color: black;
  background-color: aliceblue;
  padding: 5px 12px 5px 8px;
  border-radius: 6px;
  box-shadow: 0 5px 10px #505c620f inset, 0 3px 6px #86c7e81c;
  cursor: pointer;
}

.project_list {
  padding: 5px 8px;
  margin-top: 5px;
}

#state_name {
  color: gray;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
}

#showhide_img {
  width: 12px;
  position: relative;
  bottom: 1px;
  left: 4px;
}

/* .category-name.selected {
  background-color: #b5c7e4; 
} */
.spinner-box {
  display: flex;
  justify-content: center;
  align-items: center;
}


.days_range {
  display: block;
  font-size: 14px;
  font-weight: 500;
  color: black;
  background-color: aliceblue;
  padding: 5px 12px 5px 8px;
  border-radius: 6px;
  box-shadow: 0 5px 10px #505c620f inset, 0 3px 6px #86c7e81c;
  cursor: pointer;
  width: 150px;
}

.days_range.selected{
  background-color: #4285f4;
  color: #ffffff;
}

/* 
input.inputBox {
  font-size: 14px;
  padding: 5px 8px 4px 8px;
  border-radius: 6px;
  background-color: aliceblue;
  border: 0 solid black;
  outline-style: none;
}

.calendarWrap {
  display: inline-block;
  position: relative;
}

.calendarElement {
  position: fixed;
  bottom: 25%;
  right: 5%;
} */

.date-wrapper {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 4;
  background-color: rgba(169, 169, 169, 0.5);
}

.date-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  position: fixed;
  padding: 18px;
  padding-bottom: 22px;
  border-radius: 22px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  
}

.rdrCalendarWrapper{
  padding: 10px;
}


.date_button{
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
  background-color: #fff;
}

#date_ok{
    border: 0;
    width: 100px;
    padding: 2px 6px;
    border-radius: 6px;
    color: #ffffff;
    background-color: #4285f4;
}

#date_cancel{
  border: 0;
  width: 100px;
  padding: 2px 6px;
  border-radius: 6px;
  background-color: aliceblue;
}

.calendarWrap input{
  background-color: aliceblue !important;
  border: 0px solid beige;
  padding: 5px 12px 5px 8px; 
  box-shadow: 0 5px 10px #505c620f inset, 0 3px 6px #86c7e81c;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  width : 200px;
}

.calendarWrap input.selected{
  background-color: #4285f4 !important;
  color: #ffffff;
}



