* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.footer-image{
  height: 25px;
  width: 25px;
}

.footer-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  height: auto;
  height: 60px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  background-color: aliceblue;
  box-shadow: 0 5px 10px #505c620f inset, 0 3px 6px #505c621c;
  padding: 4px;
  box-sizing: border-box;
  z-index: 11;
}

.item {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background: transparent;
  box-sizing: border-box;
  padding-top: 20px;
}

/* .item-image {
  width: 55%;
  padding-top: 18px;
} */

.linkClass-footer{
  text-decoration: none;
  color: gray;
}

.linkClass-footer:focus{
  color:  #339AF0;
}

.profile-view{
  text-decoration: none;
  color: gray;
}

.profile-view:hover{
  color:  #339AF0;
}



 .itemName{
  font-size: 10px;
  color: #808080A1;
}
.active-icon{
  color: #339AF0;
  font-size: 12px;
}


/* UpdateSection */

 .update-section {
  display: flex;
  flex-direction: column;
  width: 100%;
  bottom: -100%;
  position: fixed;
  background: #fff;
  transition: bottom 1s ease;
  padding-bottom: 55px !important;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  z-index: -1;
  box-sizing: border-box;
  background-color: #f2f7ff;
} 

.update-section.show {
  bottom: 0;
}

.footer-header{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 28px;
  margin-top: 6px;
}

.owner-header{
  width: 50%;
  margin-left: 7%;
  
}

.closer{
  color: #339AF0;
  font-size: 15px;
}


.update-section-main{
  width: 100%;
  border-bottom: 0.5px solid rgb(12,12,12,0.3) ;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-top: 12px;
  padding-bottom: 16px;
  box-sizing: border-box;
  text-wrap: wrap;
}

.update-section-one{
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;
width: 10%;
padding-left: 2%;
margin-left: 10px;
}

.edit-image{
  width: 30px;
  height: 30px;
  margin-right: 8.5px;
}



.update-section-two{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 40%;
  /* margin-left: 2%; */
  font-size: 12px;
  color: gray;
}


.logout{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 40%;
  font-size: 12px;
  color: gray;
}

.linkClass-update{
  text-decoration: none;
  margin-top: 0.5%;
  font-size: 12px;
  color: gray;
}



@media only screen  and (min-width : 767px) {
  .update-section-main{
    padding: 6px 0;
  }

 


  .owner-header{
    padding-bottom: 12px !important;
    width: 50%;
    margin-left: 4%;
  }

  .update-section-one{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    }
  
}

  





