
.mainbox-update-user{
   display: flex;
   flex-direction: column;
   align-items: center;
   width: 100% !important;
   background-color: #f7faf2;
   height: 100vh;
   
}

.new-user-card-update {
   display: flex;
   justify-content: center;
   align-items: center;
   width: 100%;
   position: relative;
   top: 50px;
 }


  
