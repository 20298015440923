    .spinner-container {
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      z-index: 4;
      background-color: rgba(169, 169, 169, 0.5);
  }

  /* display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; 
  z-index: 10;
  background-color: transparent; */
  
  .spinner {
    height: 5vh;
    width: 5vh;
    border: 4px dotted;
    border-color: transparent #4285f4 transparent #4285f4 ;
    border-radius: 50%;
    position: fixed;
    top: 50%;
    left: 50%;
    margin: auto;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  


  