.agentinfobox-container {
  display: block;
  margin: auto;
  width: 100%;
  position: relative;
  padding-bottom: 8px; 
  z-index: 1;
  background-color: #FFF;
  box-sizing: border-box;
}

.agentinfobox-container-loans {
  display: block;
  margin: auto;
  width: 100%;
  position: relative;
  padding-bottom: 8px; 
  z-index: 1;
  background-color: #FFF;
  box-sizing: border-box;
padding: 10px 0 200px 0;
}



.category-heading {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.category-title {
  text-align: start;
  margin-left: 10px;
  margin-bottom: 10px;
}

.category-heading p {
  font-size: 16px;
  font-weight: 500;
  color: rgb(13, 13, 19);
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-gap: 12px;
}


.grid-item {
  display: block;
  margin: auto;
  width: 85%;
  height: 90px;
  position: relative;
  z-index: 1;
  background-color: #F2F7FF;
  padding: 10px 10px 10px;
  text-align: center;
  font-size: 12px;
  border-radius: 8px;
 
}

.linkClass {
  text-decoration: none;
}

.categoryImage {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 30px;
  height: 30px;
  align-items: center;
}

.categoryName {
  font-size: "12px";
  font-family: "sans-serif";
  text-align: "center";
  margin-top: 10px;
  background-color: #F2F7FF;
  color: black;
}

.headingMain {
  text-align: start;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 15px;
}


@media only screen and (min-width:468px) {
  .agentinfobox-container{
    width: 50%;
  }

  .agentinfobox-container-loans {
    width: 50%;
    }
}