*{
   margin: 0;
   padding: 0;
   box-sizing: border-box;
}

.main-container{
   background-color: rgb(228, 233, 237);
}
.linkPath{
   display: flex;
   flex-direction: column;
   justify-content: center;
   width: 100%;
   text-decoration: none;
}




.details-skeleton {
   animation: skeleton-loading 1s linear infinite alternate;
 }
 
 @keyframes skeleton-loading {
   0% {
     background-color: hsl(200 , 20% , 70%)
   }
   100% {
     background-color: hsl(200 , 20% , 95%)
   }
 }

.listProjects {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-content: center;
   width: 96%;
   overflow: scroll;
   margin: 48px auto 0 auto;
   
}

.listHoldProjects {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-content: center;
   width: 96%;
   margin: 0 auto 2px auto;
}

.listProjects::-webkit-scrollbar{
   display: none; 
 }


.mainbox-page{
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: center;
   width: 100%;
   background-color: #fff;
   border-radius: 6px;
   margin : 4px 0;
}



.product-container{
   display: flex;
   flex-direction: column;
   margin-bottom: 10px;
   width: 90%;
   border-radius: 6px;
   background-color: white;
}

.active-label {
  align-self: flex-end;
  font-size: 12px;
  font-weight: 500;
  background-color: rgb(32, 219, 48);
  padding:0 4px;
  color: #fff;
  border-bottom-left-radius: 6px;
  border-top-right-radius: 6px;
  width: 41.18px;
 }


 .hold-label{
   align-self: flex-end;
   font-size: 12px;
   font-weight: 500;
   background-color: rgb(212, 106, 20);
   padding:0 4px;
   color: #fff;
   border-bottom-left-radius: 6px;
   border-top-right-radius: 6px;
 }
 





.product-inner{
   display: flex;
   flex-direction: row;
   width: 100%;
}

.project-logo{
   width: 10%;
}

.logo{
   width: 90%;
}

.project-heading{
   font-size: 12px;
   font-weight: 500;
   color: rgb(4, 4, 64);
   width: 90%;
   align-self: center;
}
.usp-box{
   padding-top: 10px;
   padding-bottom: 10px;
   display: flex;
   flex-direction: column;
   width: 100%;
}
.usp-box-inner{
   display: flex;
   flex-direction: row;
   width: 100%;
}

.usp-image{
   width: 10%;
   height: 20%;
   display: flex;
   justify-content: center;
}

.usp-image img{
   width: 40%;
}

.usp-info{
   width: 90%;
   display: flex;
   align-items: center;
   margin-top: -3px;
}

.usp-info p{
   margin-bottom: 0;
   font-size: 12px;
   font-weight: 500;
   color: #087edf;
   text-wrap: wrap;
   
}

.goal-container{
   display: flex;
   flex-direction: column;
   justify-content: center;
   width: 100%;
   background-color: #F2F7FF;
   border-radius: 6px;
   padding-bottom: 8px;
}

.goal-container-hold{
   display: flex;
   flex-direction: column;
   justify-content: center;
   width: 90%;
   background-color: #F2F7FF;
   border-radius: 6px;
   padding-bottom: 8px;
}
.goal{
   font-size: 12px;
   font-weight: 500;
   color: rgb(11, 11, 12);
   margin-left: 10px;
   margin-top: 6px;
}

.goal-box{
   display: flex;
   flex-direction: column;
   justify-content: center;
}

.goal-box-inner{
   display: flex;
   flex-direction: row;
   align-items: center;
   width: 100%;
   text-wrap: wrap;
}

.goal-image{
   width: 10%;
   height: 20%;
   display: flex;
   justify-content: center;
}

.goal-image img{
   width: 40%;
}

.goal-info{
   width: 90%;
   display: flex;
   align-items: center;
   margin-top: 1.5px;
}

.goal-info p{
   margin-bottom: 0;
   font-size: 11px;
   font-weight: 500;
   color: #070707;
   text-wrap: wrap;
   
}


@media only screen and (min-width : 768px) {
   .listProjects{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin-top: 56px;
      margin-bottom: 0 !important;
      overflow: scroll;
   }

   .listProjects::-webkit-scrollbar{
      display: none; 
    }

    .listHoldProjects {
      width: 100%;
      margin: 2px auto 10px auto !important;
   }
    

   .details-skeleton{
      display: flex;
      align-self: center;
      margin: 0 auto;
      width: 50%;
   }
.mainbox-page{
   margin: 0 auto 10px auto;
   width: 50%;
   
}
   .back-arrow{
      width: 8%;
   }
   .left-arrow{
      width: 30%;
   }
   .Category{
      width: 20%;
   }

}