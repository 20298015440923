* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
/* SearchBar CSS */

.search-bar {
  position: absolute;
  margin-left: 88%;
  margin-top: 2%;
}

#searchbar:focus {
  border-color: #4285f4;
  outline: none;
}

.search {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100% !important;
  gap: 10px;
  margin-top: 6px;
  position: fixed;
  z-index: 15;
}

.search input {
  width: 96%;
  padding: 8px 0px 8px 8px !important;
  border-radius: 5px;
  margin-top: 8px;
  margin-left: auto;
  margin-right: auto;
  border: 0.5px solid #4285f4;
  background-color: white !important;
}

#searchbar :active {
  border-color: gray;
}

.search input::placeholder {
  text-wrap: wrap;
  color: gray;
  font-size: 11px;
  opacity: 1;
}

/* Button CSS */

.button-container {
  display: flex;
  justify-content: space-between;
  margin: auto;
  width: 96%;
  margin-top: 12px;
}

.button-box {
  display: flex;
  justify-content: space-around;
  margin-top: 8px;
  width: 100%;
}

.button-container-inner button {
  border-radius: 5px;
  background-color: #fff;
  border: solid gray 0.5px;
  font-size: 0.8rem;
  color: rgb(112, 115, 116);
  font-weight: 500;
  padding: 3px 8px;
}

.button-container-inner :focus {
  background-color: #4285f4;
  color: #ffffff;
}

.specific-leads {
  border-style: none;
}

/* LeadsContainer CSS*/



.leads-wrapper {
  display: block;
  margin: auto;
  overflow: hidden;
  padding-bottom: 120px;
}

.ReactVirtualized__List{
  top: 106px !important;
  border-radius: 6px;
}

.ReactVirtualized__List::-webkit-scrollbar {
  display: none !important;
}


.ReactVirtualized__List {
  -ms-overflow-style: none !important;  /* IE and Edge */
  scrollbar-width: none !important;  /* Firefox */
}

.leads-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 6px !important;
  padding-bottom: 10px;
}

.lead-name-section {
  display: flex;
  align-items: center;
  background-color: rgb(213, 233, 251);
  padding: 10px;
  border-top-left-radius: 8px ;
  border-top-right-radius: 8px ;
}

.agent_name_logo {
  width: 35px;
  height: 35px;
  background-color: #107cc5;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  color: #ffffff;
  margin-bottom: 0;
}

.agent_detail {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 10px;
}

.agent_detail p {
  margin-bottom: 0;
  font-size: 12px;
}

.agent_nm {
  font-weight: 600;
}

.openwatsapp {
  border: 0px;
  outline-style: none;
  background: transparent;
}

.watsappiconallleads {
  width: 11px;
  outline-style: none;
  position: relative;
  left: 5px;
}

.lead-project-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(251, 255, 238);
  padding: 10px;
}

.lead_project_logo {
  display: flex;
  align-items: center;
}

.projectImage {
  width: 35px;
}

.lead-project_detail {
  padding-left: 10px;
}

.lead-nm {
  font-size: 13px;
  margin-bottom: 0;
}

.lead-ct {
  font-size: 12px;
  color: gray;
  margin-bottom: 0;
}

.submit-title {
  display: flex;
  justify-content: center;
  font-size: 10px;
  margin-bottom: 0;
  position: relative;
  top: 4px;
}

.submit-date {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  font-size: 12px;
  color: gray;
}

.lead-status-section {
  display: flex;
  flex-direction: column;
  background-color: #f2f6fc;
  padding: 10px 15px;
  border-bottom-left-radius: 6px ;
  border-bottom-right-radius: 6px ;
}

.lead_status {
  display: flex;
  align-items: flex-start;
}

.goal_info {
  display: flex;
  gap: 24px;
}


.g2 {
  width: 200px;
}
.g3 {
  width: 100px;
}

.aryo_icon {
  width: 15px;
  position: relative;
  bottom: 5px;
}

.goal_name {
  margin-bottom: 0;
  font-size: 12px;
}

.goal_status {
  margin-bottom: 0;
  font-size: 12px;
  color: #4285f4;
}

.remark{
  font-size: 12px;
  background-color: rgb(194, 236, 255);
  width: 100%;
  padding: 2px 10px;
  border-radius: 6px;
}

.remark p{
  color: #4285f4;
  font-weight: 600;
  margin-bottom: 0;
}

@media only screen and (min-width: 768px) {
  .search {
    gap: 10px;
    justify-content: center;
    width: 100% !important;
  }

  .search input {
    width: 48% !important;
  }

  .search-bar {
    position: absolute;
    margin-left: 48%;
    margin-top: 0.5%;
  }

  .button-container {
    display: flex;
    justify-content: space-between;
    margin: auto;
    width: 48%;
    margin-top: 12px;
  }

  .leads-wrapper{
    display: block;
    margin: auto;
    width: 48% !important;
    padding-bottom: 106px;
  }
}

.search img {
  width: 30px;
  height: 30px;
  position: relative;
  right: 0;
  top: 15px;
}

.filter-option-container {
  display: flex;
  justify-content: center;
  position: fixed;
  z-index: 12;
  width: 100%;
}

.filter-option {
  display: flex;
  flex-direction: row;
  margin: 62px auto 0 auto;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background-color: aliceblue;
}
@media only screen and (min-width: 768px) {
  .button-box {
    display: flex;
    justify-content: space-between;
    width: 47%;
  }

  .filter-option {
    display: flex;
    flex-direction: row;
    margin: 62px auto 0 auto;
    width: 48%;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    background-color: aliceblue;
  }
}

.filter_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  padding: 6px 0;
}

.filter_button p {
  margin-bottom: 0;
  display: inline;
  font-size: 13px;
  color: black;
}

#filter_icon {
  width: 12px;
  height: 12px;
  position: relative;
  right: 10px;
}

.export_button {
  width: 50%;
  text-align: center;
  padding: 6px 0;
}

#selectedTime {
  background-color: aliceblue;
  color: gray;
  padding: 6px;
  border-radius: 6px;
  border: 0.5px solid gray;
  font-size: 12px;
  font-weight: 600;
  width: 140px;
  text-align: left;
}

#myLinks {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  gap: 5px;
  padding: 6px;
  border-radius: 6px;
  position: absolute;
  width: 140px;
  /* left: 8px; */
  z-index: 2;
  box-shadow: 0 5px 10px #505c620f inset, 0 3px 6px #505c621c;
}

.filter-btn {
  border-style: none;
  background-color: transparent;
  font-size: 12px;
  padding: 6px 0;
  text-align: left;
  border-bottom: 0.5px solid #4285f4;
}

p.has-filter::after {
  content: "";
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: rgb(46, 123, 218, 0.5);
  border-radius: 50%;
  position: relative;
  bottom: 2px;
  left: 4px;
  margin-bottom: 0;
}

.leads-image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  height: 80vh;
}

.leads-image img {
  width: 200px;
}

#no_leads {
  font-size: 16px;
  font-weight: 600;
}

#download_lead_link {
  display: inline;
  color: black;
  text-decoration: none !important;
  font-size: 13px;
  position: relative;
  left: 5px;
}

#download_lead_img {
  width: 16px;
  position: relative;
  right: 5px;
}

a {
  text-decoration: none !important;
}

.show_filter {
  display: flex;
  flex-direction: column;
  width: 100%;
  bottom: -100%;
  position: fixed;
  background: #fff;
  transition: bottom 500ms ease;
  padding-bottom: 55px !important;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  z-index: 10;
  box-sizing: border-box;
  background-color: #f2f7ff;
}

.show_filter.show {
  bottom: 4.9px;
  z-index: 10;
}

body.filter-active {
  overflow: hidden;
}


