* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Ubuntu", sans-serif;
}

.login-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f7faf2;
  height: 100vh;
}

.owner-box {
  display: flex;
  justify-content: flex-start;
  width: 95%;
}

.ownerDetails p:nth-child(1) {
  color: rgb(120, 113, 113) !important;
  font-size: 16px !important;
  font-weight: bolder;
  margin-bottom: 0;
}
.ownerDetails p:nth-child(2) {
  margin-bottom: 0;
  font-size: 12px !important;
  font-weight: 600;
  color: #607df1 !important;
}
.ownerDetails p:nth-child(3) {
  margin-bottom: 0;
  font-size: 12px !important;
  font-weight: 600;
  color: #607df1 !important;
}

.mainbox-login {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100% !important;
  background-color: #f7faf2;
}

.main-container-login {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.cover-image {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 500px;
  height: 500px;
}

.login-card {
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  width: 40%;
}

.login-card-one {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  width: 70%;
}

.login-welcomeMessage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.messageMobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
  top: 15px;
}

.messageMobile p {
  color: #1e3cb5;
  font-size: 22px;
  font-weight: bolder;
}

.messageMobile p span {
  color: #1e3cb5;
}

.messageMobile p:nth-child(2) {
  color: rgb(120, 113, 113);
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 0;
  margin-top: -1.2rem;
  text-align: center;
}

.mobile {
  display: none;
  visibility: hidden;
}

.signIn {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  margin-bottom: 6px;
  margin-top: 8px;
  position: relative;
  top: 7px;
}

.signIn p {
  color: #1e3cb5;
  font-size: 21px;
  font-weight: 600;
}

.inputBox {
  display: flex;
  position: relative;
  width: 100%;
  outline-style: none;
}

.inputBox:focus {
  outline-style: none;
}

.inputBox input {
  width: 100%;
  padding: 14px;
  border-radius: 6px;
  background-color: #fff;
  color: rgb(120, 113, 113);
  border-style: none;
  font-weight: 400;
  font-size: 16px;
  outline-style: none;
}

.Button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: -10px;
}
#getotpbtnId {
  width: 100%;
  padding: 15px;
  border-radius: 6px;
  font-weight: 500;
  font-size: 15px;
  border: 0px solid;
  color: white;
  background-color: #607df1;
}

.error-message {
  font-size: 12px;
  color: red;
}

.quickLinks {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  padding: 30px 10px;
  background: linear-gradient(#f7faf2, #f7faf2, #f7faf2) !important;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.boxes {
  display: flex;
  flex-direction: row;
  justify-content: space-around !important;
  align-items: center;
  width: 100%;
}

.tagline p:nth-child(1) {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 15px;
}

.tagline p span {
  color: rgb(74, 32, 143);
}

.tagline p:nth-child(2) {
  font-size: 26px;
  margin-bottom: 0;
  font-weight: 600;
  color: rgb(74, 32, 143);
}



.box1 img {
  width: 40px;
  height: 40px;
}

.box2 img {
  width: 40px;
  height: 40px;
}

.box3 img {
  width: 40px;
  height: 40px;
}

.box4 img {
  width: 40px;
  height: 40px;
}

.box5 img {
  width: 40px;
  height: 40px;
}

@media only screen and (max-width: 767px) {
  .desktop {
    visibility: hidden !important;
    display: none !important;
  }

  .login-page {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 100vh;
  }

  .mobile {
    display: block;
    visibility: visible;
    position: relative;
    bottom: 30px;
  }

  .cover-image {
    visibility: hidden !important;
    display: none !important;
  }

  .login-card {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    width: 100%;
    padding: 0 20px;
    background-color: #f7faf2;
  }

  .login-card-one {
    width: 100%;
  }
  .mainbox-login {
    background-color: white !important;
  }

  .Button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .quickLinks {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(rgb(228, 242, 229)) !important;
    width: 100%;
  }



  .tagline {
    display: flex;
    flex-direction: column;
    justify-content: space-around !important;
    align-items: center;
    text-align: center;
    width: 100%;
    margin-bottom: 12px;
  }
  

  .tagline p:nth-child(1) {
    font-weight: 600;
    font-size: 16px;
    width: 100% !important;
    margin-bottom: 6px;
  }

  .tagline p span {
    color: rgb(74, 32, 143);
  }

  .tagline p:nth-child(2) {
    font-size: 16px;
  }

}
