* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Ubuntu", sans-serif;
}

.ps-scroller {
  height: 100dvh;
  overflow: hidden;
}

.ps-container {
  background-color: aliceblue;
}

/* Navbar CSS */
.navbar-ps {
  display: flex;
  padding: 10px 0;
  background-color: #fff;
  position: fixed;
  top: 0;
  width: 100%;
  box-sizing: border-box;
  z-index: 2;
}



.category-ps {
  display: flex;
  align-items: center;
  padding-left: 15px;
}

#cat-name {
  color: black;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0.12em;
}

/* Banner CSS */
.banner-ps {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 40px;
}

.image-ps {
  width: 100%;
}

/* style={{ height:'200px'}} */

.skeleton {
  height: 200px;
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 70%);
  }
  25% {
    background-color: hsl(203, 36%, 16%);
  }

  50% {
    background-color: hsl(200, 20%, 70%);
  }
  75% {
    background-color: hsl(204, 34%, 11%);
  }

  100% {
    background-color: hsl(204, 48%, 24%);
  }
}

/* Details CSS  */

.details-container-ps {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Buttons-ps {
  /* margin: 0 auto; */
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 20px 0;
  background-color: #fff;
  margin-top: 6px;
  width: 100%;
  border-radius: 8px;
}

.Buttons-ps button {
  font-size: 11px;
  padding: 8px 7px;
  color: rgb(104, 100, 100);
  background-color: #edf2f7;
  font-weight: bold;
  border-radius: 8px;
  border-style: none;
}

.information-container-ps {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #f2f7ff;
}

.guidelines-container {
  width: 100%;
  padding-top: 2px;
}

#guide-box {
  width: 100%;
  height: 45dvh !important;
  margin-bottom: 40px;
  overflow: scroll;
}

#guide-box::-webkit-scrollbar {
  display: none;
}

.active-button {
  color: #2d73f5 !important;
 
}



/* Hide the guide-box divs by default */
.guidelines-container > #guide-box {
  display: none;
  animation-fill-mode: backwards;
}

.guidelines-container > #guide-box.active {
  display: block;
  overflow: auto;
  margin-bottom: 46px;
}

.guidelines {
  padding: 10px 8px;
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  margin: 6px 0;
}

.guidelines p {
  color: black;
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 0.3rem;
}

.list-data {
  font-size: 14px;
  color: rgb(113, 108, 108);
  padding-left: 0.5rem;
}

.list-data li{
  list-style: none;
}

/* Share Button CSS */
.share {
  display: flex;
  justify-content: center;
  width: 100%;
}

.share-button {
  padding: 10px;
  width: 95%;
  /* font-weight: bolder; */
  background-color: #1588fa;
  color: #fff;
  border-radius: 8px;
  position: fixed;
  bottom: 4px;
  border-style: none;
}

@media only screen and (min-width: 768px) {
  .navbar-ps {
    display: flex;
    padding: 10px 0;
    background-color: #fff;
    position: fixed;
    top: 0;
    width: 100%;
    box-sizing: border-box;
  }

  .category-ps {
    display: flex;
    align-items: center;
  }

  #cat-name {
    margin-bottom: 0.2rem;
  }

  .banner-ps {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 40px auto 0 auto;
    width: 50%;
    border-radius: 8px;
  }



  .image-ps {
    width: 100%;
    margin: 0 auto;
    height: 200px;
  }

  .information-container-ps {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #f2f7ff;
  }
  
  .guidelines-container {
    width: 100%;
  }

  .details-container-ps {
    display: flex;
    flex-direction: column;
  }

  .Buttons-ps {
    width: 50%;
    padding: 10px 0 10px 0;
  }

  .Buttons-ps button {
    font-size: 11px;
    padding: 6px 4px;
  }

  .information-container-ps {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    align-items: center;
    font-family: "Ubuntu", sans-serif;
    background-color: #f2f7ff;
  }

  .guidelines-container {
    width: 50%;
    padding: 6px 0px 4px 0;
    display: flex;
  }

  #guide-box {
    width: 100%;
    height: 40dvh !important;
    margin-bottom: 40px;
    overflow: scroll;
  }
  
  .share-button {
    width: 50%;
  }
}
